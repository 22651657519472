<template>
  <div id="dept">
    <el-dialog
      :title="deptFormTitle"
      width="360px"
      :visible.sync="deptDialogVisible"
      :close-on-click-modal="false"
      @close="deptDialogClose"
    >
      <el-form
        ref="deptFormRef"
        :model="deptForm"
        :rules="deptFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="deptForm.name" placeholder="请输入名称" clearable />
        </el-form-item>
        <el-form-item label="上级部门" prop="parentId">
          <el-select-tree
            v-model="deptForm.parentId"
            placeholder="请选择上级部门"
            :data="[{ id: 0, name: '顶级部门', children: deptTree }]"
            :props="{ value: 'id', label: 'name', children: 'children' }"
            :disabled-values="[]"
            :disabled="false"
            :multiple="false"
            default-expand-all
            check-strictly
            clearable
          />
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="deptForm.sort" placeholder="请输入排序" controls-position="right" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="deptDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="deptFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item>
        <el-button
          v-if="checkPermission(['DEPT_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="deptTableRef"
      :data="deptTree"
      :tree-props="{children: 'children'}"
      row-key="id"
      default-expand-all
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeightNoPage()"
      @row-click="handleRowClick"
    >
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="sort" label="排序" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['DEPT_ADD'])"
            type="text"
            icon="el-icon-plus"
            size="small"
            @click.stop="handleAdd(scope.$index, scope.row)"
          >
            新增
          </el-button>
          <el-button
            v-if="(!scope.row.children || scope.row.children.length === 0) && checkPermission(['DEPT_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['DEPT_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  addDept,
  deleteDept,
  updateDept,
  selectDeptInfo,
  selectDeptTree
} from '@/api/system/dept'

export default {
  data () {
    return {
      deptDialogVisible: false,
      deptFormTitle: '',
      deptForm: {
        id: '',
        name: '',
        parentId: '',
        sort: ''
      },
      deptFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}],
        parentId: [{ required: true, message: '上级部门不能为空', trigger: ['blur', 'change']}]
      },
      deptTree: []
    }
  },
  created () {
    selectDeptTree().then(res => {
      this.deptTree = res
    })
  },
  methods: {
    deptDialogClose () {
      this.$refs.deptFormRef.resetFields()
    },
    deptFormSubmit () {
      this.$refs.deptFormRef.validate(async valid => {
        if (valid) {
          if (this.deptFormTitle === '新增部门') {
            await addDept(this.deptForm)
          } else if (this.deptFormTitle === '修改部门') {
            await updateDept(this.deptForm)
          }
          this.deptTree = await selectDeptTree()
          this.deptDialogVisible = false
        }
      })
    },
    handleRowClick (row, column, event) {
      this.$refs.deptTableRef.toggleRowExpansion(row)
    },
    handleAdd (index, row) {
      if (row !== undefined) {
        this.deptForm.parentId = row.id
      }
      this.deptFormTitle = '新增部门'
      this.deptDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteDept(row.id)
        this.deptTree = await selectDeptTree()
      })
    },
    handleUpdate (index, row) {
      this.deptFormTitle = '修改部门'
      this.deptDialogVisible = true
      selectDeptInfo(row.id).then(res => {
        this.deptForm.id = res.id
        this.deptForm.name = res.name
        this.deptForm.parentId = res.parentId
        this.deptForm.sort = res.sort
      })
    }
  }
}
</script>

<style>
</style>
